<template>
  <div v-if="caseObj" class="edit-form float-container">

      <form>
        <div class="form-group float-child-left">
          <VueMultiselect id="items" v-model="itemObject" :options="items" :multiple="false" :close-on-select="true"
            placeholder="Leita..." label="Name" track-by="Name" />

        </div>

        <div class="form-group float-child-center">
          <select class="form-control" v-model="selectedNumberOfItems" id="exampleFormControlSelect1">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
          </select>
        </div>
      </form>
      <div class="float-child-right">
        <button class="btn btn-success mr-2" @click="addItemToCase">
          +
        </button>
      </div>
    
  </div>

  <ul class="list-group">

    <li class="list-group-item d-flex justify-content-between align-items-center" v-for="item in itemsOnCase"
      :key="item.Id">
      <button class="btn btn-danger mr-2" @click="removeItem(item.relationId)">
        -
      </button>
      {{ item.Name }}
      <span class="badge badge-primary badge-pill">{{ item.numberofitems }}</span>
    </li>
  </ul>

</template>

<script>
import CaseItemDataService from "../../services/CaseItemDataService";
import VueMultiselect from "vue-multiselect";
import ItemDataService from "../../services/ItemDataService";

export default {
  name: "case",
  components: { VueMultiselect },


  data() {
    return {
      selectedNumberOfItems: null,
      multiValue: null,
      selected: null,
      items: [],
      itemsOnCase: [],
      itemObject: null,
      caseId: null,
      caseObj: {
        Id: null,
        Name: "",
        CustomerId: null,
        PackingDate: null,
        SentDate: null,
        items: [],
      },

      message: "",
      customers: [],
    };
  },
  methods: {

    addItemToCase() {

      CaseItemDataService.addItemToCase({ "caseId": parseInt(this.caseId), "itemId": this.itemObject.Id, "numberOfItems": this.selectedNumberOfItems })
        .then((response) => {
          this.$router.go();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    removeItem(Id) {
      CaseItemDataService.delete(Id)
        .then((response) => {
          this.$router.go();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveItems() {
      ItemDataService.getAllNotOnCase(this.caseId)
        .then((response) => {
          this.items = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveItemsOnCase() {
      ItemDataService.getAllOnCase(this.caseId)
        .then((response) => {
          this.itemsOnCase = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  watch: {
    selectedNumberOfItems(newValue) {
      this.selectedNumberOfItems = parseInt(newValue);
    }
  },
  mounted() {

    this.caseId = this.$route.params.id;
    this.retrieveItems();
    this.retrieveItemsOnCase();
    // console.log('mounted ', this.caseId)



  },
};
</script>

<style>
.edit-form {
  max-width: 800px;
  margin: auto;
}

.float-container {
  width: 100%;
  float: left;
}

.float-container::after {
  content: "";
  display: table;
  clear: both;
}

.float-child-left {
  width: 65%;
  float: left;
  padding: 20px;
}

.float-child-center {
  width: 15%;
  float: left;
  padding: 20px;
}

.float-child-right {
  width: 10%;
  float: left;
  padding: 20px;
}

</style>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
