import http from "../http-common";

class CaseItemDataService {
  

  get(Id) {

    return http.get(`/caseitems/${Id}`);
  }

  getAllByCasesId(Id){
    console.log('ID*', Id)
    return http.get(`/casesitems/${Id}`)
  }

  addItemToCase(data){
    console.log('adding case items')
    return http.post(`/caseitems`, data)
  }
  
  delete(Id) {
    return http.delete(`/caseitems/${Id}`);
  }

}

export default new CaseItemDataService();
